// some common things used on the cloud portal page

// Clicks 'fileInput' element so a file selection dialog is opened
const selectFiles = function()
{
	let fileInput = document.getElementById('fileInput');
	fileInput.click();
};

const getel  = function(id)
{
	return document.getElementById(id);
};

const crel = function(type)
{
	return document.createElement(type);
};

// Redirecto to the manage group page
const goToGroupPage = function()
{
	window.location = "index.html";
	window.body.load();
};

// Code for handling a page that should only be visible when connected to a group
const handleNonPublicPage = function() {
	// Redirect to manage group page if not connected to group
	if (!isLinked()) goToGroupPage();
};

const notificationMessage = function(parentElement, title, text, extraStyle)
{
	let div = crel('div');
	div.classList.add('textbubble');
	div.classList.add('notification');
	div.classList.add(extraStyle);
	parentElement.appendChild(div);
	let divInner = crel('div');
	divInner.classList.add('flex-1');
	div.appendChild(divInner);
	let h2 = crel('h2');
	h2.classList.add('notification__title');
	h2.textContent = title;
	divInner.appendChild(h2);
	let p = crel('p');
	p.classList.add('notification__body');
	p.textContent = text;
	divInner.appendChild(p);
	let button = crel('button');
	button.textContent = "Dismiss";
	button.onclick = function () { div.remove(); };
	div.appendChild(button);
};

// Creates error notification inside parentElement
const errorNotification = function(parentElement, title, text)
{
	notificationMessage(parentElement, title, text, 'notification--error');
};

// Creates warning notification inside parentElement
const warningNotification = function(parentElement, title, text)
{
	notificationMessage(parentElement, title, text, 'notification--warning');
};

// Shows error message inside element with the id 'errorArea'
const showErrorMessage = function(message)
{
	let errorArea = document.getElementById('errorArea');
	errorNotification(errorArea, 'Error', message);
};

// Shows warning message inside element with the id 'errorArea'
const showWarningMessage = function(message)
{
	let errorArea = document.getElementById('errorArea');
	warningNotification(errorArea, 'Warning', message);
};


// checks if HTML web storage is supported in the browser
const isStorageSupported = function() {
	if (typeof(Storage) !== "undefined") {
		console.log("Storage is supported.");
		return true;
	}
	else {
		console.warn("Storage is not supported.");
		return false;
	}
};

// Get the (encryption key)/(group id) from the URL bar in the browser
// Returns it as a base64 encoded string
const getEncryptionKeyFromUrl = function() {
	try {
		return Util.getUrlPar(window.location, 'groupid');
	}
	catch (error) {
		console.error("Unexpected error while trying to get group ID from URL!", error);
		return null;
	}
};

// Try Retrieving encryption key from URL or storage
// If it can't be retrieved, null is returned
const retrieveEncryptionKey = function() {

	// First try getting it from the URL
	let base64 = getEncryptionKeyFromUrl();

	// If we can't get it from the URL then try getting it from local storage
	if (base64 === null) {
		try {
			if (isStorageSupported()) {
				base64 = localStorage.getItem(Constants.EncryptionKeyItemName);
			}
		}
		catch (error) {
			console.error("Unexpected error while trying to retrieve encryption key from local storage: ", error);
		}
	}

	if (base64 === null || base64 === '' || base64 === 'null') return null;

	let encrKeyBytes = null;
	try {
		encrKeyBytes = CryptoUtil.base64ToBytes(base64); // Convert it to bytes
	} catch (error) {
		console.error("Unexpected error while trying to convert base64 encoded group id: ", error);
	}

	return encrKeyBytes === '' ? null : encrKeyBytes;
};

// Try storing encryption key in browser storage
// for later use
const storeEncryptionKey = function(encrKey)
{
	if (isStorageSupported())
	{
		var base64 = CryptoUtil.bytesToBase64(encrKey);
		localStorage.setItem(Constants.EncryptionKeyItemName, base64);
	}
};

const isLinked = function()
{
	var key = retrieveEncryptionKey();
	if (key != null)
	{
		return true;
	}
	return false;
};

const makeElementHidden = function(el)
{
	el.classList.add('hidden');
};

const makeElementVisible = function(el)
{
	el.classList.remove('hidden');
};

// If a group id is in the URL of the page, then add that to the tab links
const addGroupIdToTabLinksIfNeeded = function () {
	let groupId = getEncryptionKeyFromUrl();
	if (groupId != null) {
		let links = [getel('indexLink'), getel('uploadsLink'), getel('downloadsLink'), getel('accLink')];
		let i = 0;
		for (i = 0; i < links.length; i++)
			links[i].href = Util.addOrUpdateUrlParameter(links[i].href, 'groupid', groupId);
	}
};

// Remove entry from index file in cloud
// entryHash : hash of the entry
// onError : method to call if there is an error
const removeEntryFromIndex = async function (entryHash, onError)
{
	let result = {};
	result.success = false;

	if (!isLinked())
	{
		goToGroupPage();
		return result;
	}

	try
	{
		let encrkey = retrieveEncryptionKey();
		console.log('Removing entry from index file.');
		result = await Cloud.removeFromIndex(entryHash, encrkey);
	}
	catch(err)
	{
		console.error('Error in removeEntryFromIndex:', err);
	}

	if (!result.success)
	{
		if (onError != null)
		{
			onError('A failure occured while trying to remove entry!');
		}
	}

	return result;
};

// For downloading a cloud resource in a browser
// Will download object with name objectName
// Opens a save file dialog
// objectName : name/hash of object in cloud
// nrOfParts : number of parts for multipart download
// filename : filename to show
// onError : Method to call when there is an error
const downloadCloudObject = async(objectName, nrOfParts, totalNrOfBytes, filename, onError, onWarning) =>
{
	if (!isLinked())
	{
		goToGroupPage();
		return;
	}

	try
	{
		if (totalNrOfBytes > 200000000)
		{
			if (onWarning != null)
			{
				onWarning('Files are larger than 200MB, download might fail.');
			}
		}

		var encrkey = retrieveEncryptionKey();
		console.log('Getting object ' + objectName + ' from cloud.');
		const getRes = await Cloud.getCloudFile(objectName, encrkey, nrOfParts);

		if (getRes.success)
		{
			console.log('Got file from cloud.');
			var filenameWithoutExt = Util.getFilenameWithoutExtension(filename);
			Cloud.saveBytesAsFile(getRes.decryptedBytes, "application/zip", "Arkio_" + filenameWithoutExt + ".zip");
		}
		else
		{
			if (onError != null)
			{
				onError('Failed to download file from cloud!');
			}
			
		}
	}
	catch(err)
	{
		console.error('Error in downloadCloudObject', err);
		if (onError != null)
		{
			onError('A failure occured while trying to download a file from the cloud!');
		}
	}
};

// Creates a "button" cell in a html table
// Used for creating download and remove buttons
// text : The text on the button. The button with the text is not included is this is null.
// iconHTML : html code for an icon to show. Icon is not included if this is null.
// action : Function to call when user clicks on the button
// hideIconOnWiderScreens : If true, icon will only be shown on smaller screens such as for mobile devices
// tooltip : A tooltip to show for the button
const createButtonTD = function(text, iconHTML, action, hideIconOnWiderScreens, tooltip)
{
	let td2 = document.createElement('td');
	let td2div = document.createElement('div');
	td2div.setAttribute('class', 'flex justify-end');
	
	if (text) {	
		let td2a = document.createElement('a');
		td2a.setAttribute('href', '#');
		td2a.setAttribute('class', 'btn btn-small hidden md:inline');
		td2a.onclick = action;
		if (tooltip) td2a.setAttribute('title', tooltip);
		let td2span = document.createElement('span');
		td2span.textContent = text;
		td2a.appendChild(td2span);
		td2div.appendChild(td2a);
	}

	if (iconHTML) {
		let td2a2 = crel('a');
		td2a2.setAttribute('href', '#');
		if (hideIconOnWiderScreens) td2a2.setAttribute('class', 'inline md:hidden w-4');
		td2a2.onclick = action;
		td2a2.innerHTML = iconHTML;
		if (tooltip) td2a2.setAttribute('title', tooltip);
		td2div.appendChild(td2a2);
	}

	td2.appendChild(td2div);

	return td2;
};

const getFilenamePlusExtra = function(tableDataItem, showFileCount) {
	let entry = tableDataItem.entryData.entryValue;
	let filename = tableDataItem.name;
	let fileCount = 0;
	if (entry.File != null) {
		filename = entry.File.Filename;
		fileCount = entry.File.FileCount;
	}
	// filename column
	let filenamePlusExtra = '';
	if (filename) filenamePlusExtra = Util.getFilenameFromPath(filename);

	// Try getting the width of the section
	let contentWidth = 0;
	try
	{
		let contentSection = document.getElementById('contentSection');
		let positionInfo = contentSection.getBoundingClientRect();
		contentWidth = positionInfo.width;
	}
	catch(error)
	{
		console.error('Error when trying to get width of element', error);
	}

	if (contentWidth === 0)
	{
		// In case we can't get the width of the element then we let
		// the width of the page control how many characters are shown
		// So this will work well different screen sizes
		contentWidth = document.documentElement.scrollWidth;
	}

	let maxLen = Math.floor(contentWidth/20);

	if (filenamePlusExtra) {
		if (showFileCount && fileCount > 1)
		{
			filenamePlusExtra = filenamePlusExtra + " and " + (fileCount - 1) + " other file(s)";
		}
		if (filenamePlusExtra.length > maxLen)
		{
			filenamePlusExtra = filenamePlusExtra.substring(0, maxLen-3) + "...";
		}
	}
	return filenamePlusExtra;
};

// Get the created date as a string ready to display in the UI
const getCreatedDateForUI = function(tableDataItem) {
	let entry = tableDataItem.entryData.entryValue;
	let created = entry.Created;
	// Reformatting the date string a bit
	let createdDateForUI = '';
	if (created) {
		let month = created[0] + created[1];
		let day = created[3] + created[4];
		let year = created[6] + created[7] + created[8] + created[9];
		createdDateForUI = year + '/' + month + '/' + day + created.substring(10);
	}
	return createdDateForUI;
};

const createTableRow = function(tableDataItem, includeStatus, enableDownload,
	enableRemove, showFileCount, onErr, onWarn, onEntryRemoved) {

	// For ensuring that the value of hash
	// is fixed in downloadCloudObject
	const createDownloadFunc = function(hash, nrOfParts, totalNrOfBytes, filename, onError, onWarning)
	{
		return function() { downloadCloudObject(hash, nrOfParts, totalNrOfBytes, filename, onError, onWarning); };
	};
	const createRemoveFunc = function(hash, onError)
	{
		return async function() {
			if (confirm("Remove this uploaded file?") === true) {
				let res = await removeEntryFromIndex(hash, onError);
				if (res.success)
				{
					if (onEntryRemoved != null) {
						onEntryRemoved(res.indexFile);
					}
				}
			}
		};
	};

	let entry = tableDataItem.entryData.entryValue;
	let entryHash = tableDataItem.entryData.entryHash;
	let filename = tableDataItem.name;
	let status = tableDataItem.status;
	let nrOfParts = 1;
	let totalNrOfBytes = 0;
	let filenamePlusExtra = getFilenamePlusExtra(tableDataItem, showFileCount);
	let createdDateForUI = getCreatedDateForUI(tableDataItem);
	let fileIcon = null;
	let isFromACC = entry.ACC_AutomationJob ? true : false;

	if (entry.File != null) {
		nrOfParts = entry.File.NrOfParts;
		totalNrOfBytes = entry.File.TotalNrOfBytes;
		filename = entry.File.Filename;
		fileIcon = entry.File.Icon;
	}

	// the table row
	let tr = document.createElement('tr');
	// add some hidden data in the row, that will be used
	tr.dataset.entryHash = entryHash;
	tr.dataset.status = status;
	tr.dataset.filenameplusextra = filenamePlusExtra;
	tr.dataset.createddateforui = createdDateForUI;

	let iconTD = crel('td');

	let iconId = null;
	if (status === 'uploading' ||  status == 'starting' || status === 'pending' || status === 'inprogress') iconId = 'SpinnerIcon';
	if (status === 'success') {
		iconId = 'ModelIcon';
		if (isFromACC) iconId = 'ACCIcon';
		else if (fileIcon) {
			if (fileIcon === Constants.Icon.Images) iconId = 'ImageIcon';
			if (fileIcon === Constants.Icon.Revit) iconId = 'RevitIcon';
			if (fileIcon === Constants.Icon.Rhino) iconId = 'RhinoIcon';
			if (fileIcon === Constants.Icon.SketchUp) iconId = 'SketchupIcon';
			if (fileIcon === Constants.Icon.Unity) iconId = 'UnityIcon';
		}
	}
	if (status === 'cancelled' ||
		status === 'failedLimitProcessingTime' ||
		status === 'failedDownload' ||
		status === 'failedInstructions' ||
		status === 'failedUpload' ||
		status === 'failedUploadOptional') iconId = 'ErrorIcon';

	if (iconId) {
		let iconHTML = getel(iconId);
		iconTD.innerHTML = iconHTML.innerHTML;
	}

	tr.appendChild(iconTD);

	let td0 = document.createElement('td');
	let td0div = document.createElement('div');
	td0div.setAttribute('class', 'flex items-center gap-2 max-w-2xs sm:max-w-none');
	let td0span = document.createElement('span');
	td0span.setAttribute('class', 'truncate flex-1');
	td0span.textContent = filenamePlusExtra;
	//td0div.appendChild(td0icon);
	td0div.appendChild(td0span);
	td0.appendChild(td0div);

	// date column
	let td1 = document.createElement('td');
	td1.textContent = createdDateForUI;

	tr.appendChild(td0);
	tr.appendChild(td1);

	if (includeStatus) {
		let td1_0 = crel('td');
		td1_0.textContent = status;
		tr.appendChild(td1_0);
	}

	if (enableDownload)
	{
		// download column
		let downloadFunction = createDownloadFunc(entryHash, nrOfParts, totalNrOfBytes, filename, onErr, onWarn);

		// Get the html code for the download icon
		let downloadIcon = document.getElementById("DownloadCircleIcon");
		let iconHTML = downloadIcon.innerHTML;

		let td2 = createButtonTD('Download', iconHTML, downloadFunction, true, "Download this resource to your machine.");

		tr.appendChild(td2);
	}

	if (enableRemove)
	{
		let removeFunction = createRemoveFunc(entryHash, onErr);

		// Getting icon html in similar way as with the download icon
		let trashIcon = document.getElementById("TrashIcon");
		let iconHTML = trashIcon.innerHTML;
		let td3 = createButtonTD(null, iconHTML, removeFunction, false, "Permanently delete this resource.");
		tr.appendChild(td3);
	}

	return tr;
};

// Fill a table with cloud entries
// tableBody: table body html element
// tableData: An object containing the data that will be put into the table
// enableDownload: If true, a download button will be added for each row
// enableRemove : If true, a remove button will be added for each row
// showFileCount: If true, then number of extra files will be shown in the filename field
// onEntryRemoved : Method to call after an entry has successfully been removed
const fillTableWithEntries = function(tableBody, tableData, includeStatus,
	enableDownload, enableRemove, showFileCount, onErr, onWarn, onEntryRemoved) {
	tableBody.innerHTML = '';
	let n = tableData.length;
	for (let i = 0; i < n; i++) {
		let tr = createTableRow(tableData[i], includeStatus, enableDownload,
			enableRemove, showFileCount, onErr, onWarn, onEntryRemoved);
		// Add row to body of table
		tableBody.appendChild(tr);
	}
};

// Update table showing entries and more with new data
// Does not destroy the table body and re-create it as
// is done in fillTableWithEntries
const updateTableWithData = function(tableBody, tableData, includeStatus,
	enableDownload, enableRemove, showFileCount, onErr, onWarn, onEntryRemoved) {

	// REMARK
	// Using Set and Map data structures here to make this faster.
	// Might be better to pass them along as arguments
	// to prevent memory problems.
	// It looks to me though that memory consumption
	// is not that much of a problem here.

	// See if there are entry hashes in current html table
	// That cannot be found in tableData
	const entryHashes = new Set();
	for (let i = 0; i < tableData.length; i++) entryHashes.add(tableData[i].entryData.entryHash);
	
	// REMARK
	// Might be better to pass this as an argument
	// instead of creating a new Map object all the time
    const rowsByEntryHash = new Map();
    const rows = tableBody.rows;
	let maxIndex = rows.length - 1;
	// Here we start at the last row and go backwards, to avoid
	// index shifting issues when rows are removed.
    for (let i = maxIndex; i >= 0; i--) {
        const rowEntryHash = rows[i].dataset.entryHash;  // Access the data-entryHash attribute
        if (rowEntryHash) {
			// If the entry hash of the row is not found in the new data
			// then it should be removed
			if (!entryHashes.has(rowEntryHash)) rows[i].remove();
			else rowsByEntryHash.set(rowEntryHash, rows[i]);
		}
    }
	maxIndex = tableData.length - 1;
	for (let i = maxIndex; i >= 0; i--) {
		let entryHash = tableData[i].entryData.entryHash;
		let isOngoingUploadTask = tableData[i].isOngoingUploadTask;
		let removeButtonShown = enableRemove && !isOngoingUploadTask;
		// If the item is not already in the table
		// then add it to the table, else
		// update the item in the table with the new data
		// but only if data needs to be updated.
		if (!rowsByEntryHash.has(entryHash)) {
			let tr = createTableRow(tableData[i], includeStatus, enableDownload,
				removeButtonShown, showFileCount, onErr, onWarn, onEntryRemoved);
			if (tableBody.firstChild) tableBody.insertBefore(tr, tableBody.firstChild);
			else tableBody.appendChild(tr);
		} else {
			let rowToReplace = rowsByEntryHash.get(entryHash);
			// Check if replacement is needed
			// Best to only modify the row if it needs to be modified.
			let modNeeded = false;
			if (tableData[i].status !== rowToReplace.dataset.status) modNeeded = true;
			if (getFilenamePlusExtra(tableData[i], showFileCount) !== rowToReplace.dataset.filenameplusextra) modNeeded = true;
			if (getCreatedDateForUI(tableData[i]) !== rowToReplace.dataset.createddateforui) modNeeded = true;

			if (modNeeded) {
				let tr = createTableRow(tableData[i], includeStatus, enableDownload,
					removeButtonShown, showFileCount, onErr, onWarn, onEntryRemoved);
				rowToReplace.replaceWith(tr);
			}
		}
	}
};

// get index file if possible
// returns index file object if it was possible to get it
// else returns null
// need to be connected to group
// if not connected, this will redirect to the group page
const getIndexFile = async function(onError)
{
	if (!isLinked())
	{
		goToGroupPage();
	}
	else
	{
		let encrkey = retrieveEncryptionKey();
		let indexRes = await Cloud.getIndexFromCloud(encrkey);

		if(!indexRes.success)
		{
			if (indexRes.httpStatusCode === 404)
			{
				// This should not happen since the index file should
				// always exist after a group has been created.
				console.error('No index file in cloud!');
			}
			else
			{
				console.error('Failed to download index file from cloud!');
				onError("Failed to download records from cloud!");
			}
		}
		else
		{
			let indexFile = indexRes.indexFile;
			return indexFile;
		}
	}
	return null;
};

// Updates uploading progress/complete messages
// to show either a progressing or upload complete message
// error : set this to true if an error has happened
// If error is true then an upload complete message won't
// be shown although an progressing message could be
// shown if there are still files being processed.
// error: If true then the upload complete message
// will not be shown, even if the nr of files being sent
// has reached 0
const updateUploadingMessages = function(error, totalNrOfFilesBeingSent, nrOfOngoingUploadTasks)
{
	let uploadComplete = document.getElementById('uploadCompleteNotificationDiv');
	let uploadProgress = document.getElementById('uploadProgressNotificationDiv');

	if (nrOfOngoingUploadTasks === 0)
	{
		makeElementHidden(uploadProgress);
		if (!error)
		{
			makeElementVisible(uploadComplete);
		}
	}
	else
	{
		let extraUploadText = 'Please do not leave this page until the uploads have finished.';
		makeElementHidden(uploadComplete);
		let uploadProgressBody = document.getElementById('uploadProgressNotificationBody');
		if (totalNrOfFilesBeingSent > 0)
		{
			uploadProgressBody.textContent = 'Uploading ' + totalNrOfFilesBeingSent + ' files. ' + extraUploadText;
		}
		else
		{
			uploadProgressBody.textContent = 'Uploading. ' + extraUploadText;
		}
		makeElementVisible(uploadProgress);
	}
};

// Opens save dialog for user to save file to disk
// buffer : A buffer with data to save
// filename : Name of the file to save
const saveBufferToDisk = function(buffer, filename)
{
	var blob = new Blob([buffer], { type: 'application/octet-stream' });
	var link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = filename;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

// Create the data needed for filling the tables that are shown
// in the UI
// entries : Entries from the Cloud index file
// ongoingUploadTasks : Uploading tasks that are currently being processed
// on the cloud portal
const createTableData = async function(entries, ongoingUploadTasks) {

	let tableData = [];

	// Add the ongoing upload tasks (Files that are currently being processed uploaded,
	// whose entries are still not in the Cloud)
	if (ongoingUploadTasks)
		for (const eHash in ongoingUploadTasks) {

			// Not sure if it's possible for this to happen but adding this check to be safe
			// We don't want to show a processing row for an entry that's already in the cloud
			if (eHash in entries) continue;

			let task = ongoingUploadTasks[eHash];
			tableData.push({ name : task.Filename, status : 'uploading', isOngoingUploadTask : true,
				entryData : { entryHash : eHash, entryValue : {}}});
		}

	if (entries && entries.length > 0) {
		// Get info on design automation items
		let jobIds = '';
		for (const key in entries) {
			let entry = entries[key];
			if (entry.entryValue && entry.entryValue.ACC_AutomationJob) {
				if (jobIds.length > 0) jobIds += ',';
				jobIds += entry.entryValue.ACC_AutomationJob.JobId;
			}
		}
		let jobInfo = null;
		if (jobIds.length > 0) {
			jobInfo = await AccServices.getJobInfo(jobIds);
		}

		for (const key in entries) {
			let entry = entries[key];
			if (entry.entryValue) {
				let entryValue = entry.entryValue;
				if (entryValue.File)
					tableData.push({ name : entryValue.File.Filename, status : 'success', entryData : entry });
				if (entryValue.ACC_AutomationJob) {
					let jobId = entryValue.ACC_AutomationJob.JobId;
					if (jobId in jobInfo)
						tableData.push({ name : jobInfo[jobId].filename, status : jobInfo[jobId].status, entryData : entry });
				}
			}
		}
	}
	return tableData;
};

const getCurrentUrlWithoutPars = function() { return window.location.origin + window.location.pathname };

const logOutOfACC = async function() {
	// Remove token from local storage
	let redirected = false;
	let tokenData = AccUtil.retrieveAccessTokenDataFromLocalStorage();
	localStorage.setItem(Constants.AutodeskUserAccessTokenItemName, null);
	if (tokenData) {
		// Revoke the token
		try {
			await AccServices.revokeToken(tokenData.access_token, tokenData.refresh_token);
		} catch (error) {
			console.error('Failed to revoke access token: ', error);
		}
		// Log user out of Autodesk
		try {
			const url = 'https://developer.api.autodesk.com/authentication/v2/logout?' +
			'&post_logout_redirect_uri=' + getCurrentUrlWithoutPars();
			location.href = url;
			redirected = true;
		} catch (error) {
			let msg = "Failed to redirect to the Autodesk logout page."
			console.error(msg, error);
			showErrorMessage(msg);
		}
	}
	return redirected;
};
